<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="closeModal"
        width="500px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
        @open="getDetails"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.edit_history') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-16 px-10">
            <el-form
                ref="form"
                v-loading="$wait.is('loading.details')"
                :model="formData"
                label-position="top"
                :rules="rules"
                element-loading-spinner="el-custom-spinner"
                class="grid grid-cols-2 text-center"
                @submit.native.prevent
                @keyup.native.enter="updateHistory"
            >
                <el-form-item :label="$t('clients.hours')" prop="hours">
                    <el-input-number v-model="formData.hours" :min="0" :max="12" />
                </el-form-item>
                <el-form-item :label="$t('clients.minutes')" prop="minutes">
                    <el-input-number v-model="formData.minutes" :min="0" :max="59" :step="5" />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('updating')" @click="updateHistory">{{ $t('common.update') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './history.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        selectedHistoryId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            formData: {
                hours:   0,
                minutes: 0,
            },
            rules: {
                hours:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                minutes: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async getDetails() {
            this.$wait.start('loading.details');
            try {
                const data = await Api.getDetails(this.selectedHistoryId);
                this.formData.hours = data.duration_hours;
                this.formData.minutes = data.duration_minutes;
            } finally {
                this.$wait.end('loading.details');
            }
        },

        async updateHistory() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating');
            try {
                await Api.update(this.selectedHistoryId, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.closeModal();
                this.$emit('refresh');
            } finally {
                this.$wait.end('updating');
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
